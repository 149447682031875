<template>
  <div>
    <button
      class="ico-bt mr-2 red-bt"
      :class="isFavourite ? 'active' : ''"
      @click="toggleFavourite"
      :loading="isLoading"
    >
      <b-icon icon="heart"></b-icon>
    </button>
  </div>
</template>

<script>

import { mapState } from "vuex";
import toast from "@/mixins/toast.js"; 


export default {
  name: "FavHeart",
  props: {
    advID: {
      type: String,
      required: true,
    },
  },
  mixins: [toast],
  data() {
    return {
      isLoading: false,
    };
  },
    computed: {
    ...mapState({
      favs: (state) => state.favorites.favs,
      user: (state) => state.users.user,
    }),
    isFavourite() {
      return this.favs.includes(this.advID);
    },
  },
  methods: {
    toggleFavourite() {
      if (!this.user.isLogged) {
        this.toast("Please login to add to favorites", "is-danger");
        return;
      }
      if (this.isFavourite) {
        this.removeFavourite();
      } else {
        this.addFavourite();
      }
    },
    addFavourite() {
      console.log("add to favorites", this.advID);
      this.axios
        .post("/favorites/manage_favorites.php", {
          action: "addFav",
          advID: this.advID,
          token: localStorage.sxToken,
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.success) {
            //this.$store.commit("users/addFavorites", this.advID);
            this.$store.dispatch("favorites/loadFavorites");
          } else {
            console.log("could not update favorites");
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    removeFavourite() {
      console.log("remove from favorites", this.advID);
      this.axios
        .post("/favorites/manage_favorites.php", {
          action: "deletFav",
          advID: this.advID,
          token: localStorage.sxToken,
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.success) {
            //this.$store.commit("users/remFavorites", this.advID);
            this.$store.dispatch("favorites/loadFavorites");
          } else {
            console.log("could not update favorites");
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
<style scoped>
.advert-map {
  height: 400px;
}
</style>
