<template>
  <div class="columns popnow">
    <div v-for="(categoryData, categoryNameEn) in popularAdverts" :key="categoryNameEn" class="column">
      <div class="box popular-category-box">
        <div class="category-title"><router-link class="cat-link dark" :to="'/category/' + categoryData.slug"  >{{ categoryData.labels[$store.state.lang] || categoryData.labels.en }}</router-link></div>
        <div class="sx-box-grid">
          <router-link 
            v-for="(advert, index) in categoryData.adverts" 
            :key="index" 
            :to="'/advert/' + advert.id" 
            class="art-grid-item"
          >
            <img 
              v-if="advert.ad_images && advert.ad_images.images && advert.ad_images.images.length > 0"
              :src="showImage(advert.ad_images.images[0].image)"
              :alt="advert.ad_title_en"
              class="art-image"
            />
            <img 
              v-else
              src="https://salex.md/img/noimage.png"
              alt="No Image"
              class="a-image"
            />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopularNow',
  data() {
    return {
      popularAdverts: {}
    };
  },
  mounted() {
    this.fetchPopularAdverts();
  },
  methods: {
    fetchPopularAdverts() {
      this.axios.post(`${this.$env.api}/adverts/get_popular.php`)
        .then(response => {
          console.log('Popular adverts:', response.data);
          if (response.data.success) {
            this.popularAdverts = response.data.items;
          }
        })
        .catch(error => {
          console.error('Error fetching popular adverts:', error);
        });
    },
    showImage(image) {
      if (image) {
        let imageOut = image.replace(/\.[^/.]+$/, "") + "_h400.webp";
        return this.$env.imagesURL + "gallery/400/" + imageOut;
      } else {
        return "https://salex.md/img/noimage.png";
      }
    }
  }
};
</script>

<style scoped>
.popnow {
  position: relative;
  z-index: 1;
}

.popular-category-box {
  position: relative;
}

.category-title {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 10px;
  text-align: center;
}

.cat-link {
  color: inherit;
  text-decoration: none;
  transition: color 0.3s ease;
}

.cat-link.dark {
  color: #333;
  font-weight: 600;
}

.cat-link:hover {
  color: #666;
}

.sx-box-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
  height: 300px;
}

.art-grid-item {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}

.art-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.art-grid-item:hover .art-image {
  transform: scale(1.1);
}
</style>
