export default {
  methods: {
    formCheckOne(...keys) {
      let isValid = false;
  
      for (const key of keys) {
        if (!this.form[key].data) {
          this.form[key].message = this.$i18n.t("This field is required");
          this.form[key].status = "is-danger";
        } else {
          this.form[key].message = null;
          this.form[key].status = "is-success";
          isValid = true;
        }
      }
  
      return isValid;
    },
    formCheck(e) {
      if (!this.form[e].data) {
        this.form[e].message = this.$i18n.t("This field is required");
        this.form[e].status = "is-danger";
        return false;
      } else {
        this.form[e].message = null;
        this.form[e].status = "is-success";
        return true;
      }
    },
    phoneCheck(e) {
      //must be a number, and 8 digits long, and start with 6, 7, 8, or 9
      let re = /^[6-9]\d{7}$/;
      if (!this.form[e].data || !re.test(this.form[e].data)) {
        this.form[e].message = this.$i18n.t("Please enter a valid phone number");
        this.form[e].status = "is-danger";
        return false;
      } else {
        this.form[e].message = null;
        this.form[e].status = "is-success";
        return true;
      }
    },
    usernameCheck(e) {
      // Check if the field is empty
      if (!this.form[e].data) {
          this.form[e].message = this.$i18n.t("This field is required");
          this.form[e].status = "is-danger";
          return false;
      }
      // Check for white spaces
      else if (/\s/.test(this.form[e].data)) {
          this.form[e].message = this.$i18n.t("no white spaces");
          this.form[e].status = "is-danger";
          return false;
      }
      // Check for non-ANSI (non-ASCII) characters
      else if (/[^\x00-\x7F]/.test(this.form[e].data)) {
          this.form[e].message = this.$i18n.t("username ANSI");
          this.form[e].status = "is-danger";
          return false;
      } 
      else {
          this.form[e].message = null;
          this.form[e].status = "is-success";
          return true;
      }
    },
    emailCheck() {
      let re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
      if (this.form.email.data === "" || !re.test(this.form.email.data)) {
        this.form.email.status = "is-danger";
        this.form.email.message = this.$i18n.t("Please enter a valid email address");
        return false;
      } else {
        this.form.email.status = "is-success";
        this.form.email.message = null;
        return true;
      }
    },
    generalEmailCheck(e) {
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
      if (e === "" || !re.test(e)) {
        return false;
      } else {
        return true;
      }
    },
    passCheck() {
      let password = this.form.pass.data;
      
      // check if the password is empty
      if (password === "") {
        this.form.pass.message = this.$i18n.t("Please enter a Password");
        this.form.pass.status = "is-danger";
        return false;
      }
    
      // check if the password is at least 8 characters long
      if (password.length < 4) {
        this.form.pass.message = this.$i18n.t("Password should be at least 6 characters long");
        this.form.pass.status = "is-danger";
        return false;
      }

      //no white spaces
      if (/\s/.test(password)) {
        this.form.pass.message = this.$i18n.t("no white spaces");
        this.form.pass.status = "is-danger";
        return false;
      }
    
      // check if the password contains both lowercase and uppercase letters
      // if (password === password.toLowerCase() || password === password.toUpperCase()) {
      //   this.form.pass.message = this.$i18n.t("Password should contain both uppercase and lowercase letters");
      //   this.form.pass.status = "is-danger";
      //   return false;
      // }
    
      // check if the password contains at least one number
      // if (!/\d/.test(password)) {
      //   this.form.pass.message = this.$i18n.t("Password should contain at least one number");
      //   this.form.pass.status = "is-danger";
      //   return false;
      // }
    
      // check if the password contains at least one special character
      // if (!/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(password)) {
      //   this.form.pass.message = this.$i18n.t("Password should contain at least one special character");
      //   this.form.pass.status = "is-danger";
      //   return false;
      // }
      
      this.form.pass.message = null;
      this.form.pass.status = "is-success";
      return true;
    },
    // passCheck() {
    //   if (this.form.pass.data === "") {
    //     this.form.pass.message = this.$i18n.t("Please enter a Password");
    //     this.form.pass.status = "is-danger";
    //     return false;
    //   } else {
    //     this.form.pass.message = null;
    //     this.form.pass.status = "is-success";
    //     return true;
    //   }
    // },
    // passCheck(e) {
    //   let paswd = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;
    //   console.log(!this.form[e].data.match(paswd));
    //   if (this.form[e].data === "" || !this.form[e].data.match(paswd)) {
    //     this.form[e].message =
    //       "Please enter a Password [7 to 15 characters which contain at least one numeric digit and a special character]";
    //     this.form[e].status = "is-danger";
    //     return false;
    //   } else {
    //     this.form[e].message = null;
    //     this.form[e].status = "is-success";
    //     return true;
    //   }
    // },
    humanCheck() {
      if (this.form.human.data == this.form.human.summ) {
        this.form.human.status = "is-success";
        this.form.human.message = "";
        return true;
      } else {
        this.form.human.status = "is-danger";
        this.form.human.message = this.$i18n.t("You did not pass the human test.");
        return false;
      }
    },
  },
};


// export default {
//   methods: {
//     formCheck(e) {
//       if (!this.form[e].data) {
//         this.form[e].status = "is-danger";
//         this.form[e].message = "Please fill out all required fields";
//         return false;
//       } else {
//         this.form[e].status = "is-success";
//         return true;
//       }
//     },
//     emailCheck(e) {
//       var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//       if (!this.form[e].data || !re.test(String(e).toLowerCase())) {
//         this.form[e].status = "is-danger";
//         this.form[e].message = "Please enter a valid email address";
//         return false;
//       } else {
//         this.form[e].status = "is-success";
//         return true;
//       }
//     },
//   },
// };
