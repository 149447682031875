<template>
  <div>
    <!-- Floating Button / Chat Box -->
    <div
      class="chat-container"
      :class="$store.state.modals.filter ? 'filter-on' : ''"
    >
      <!-- Chat Interface -->
      <div v-if="showChat" class="chat-box">
        <div class="chat-header salex-line">
          {{$t('Help Bot')}}
          <button type="button" class="delete" @click="hideChatBox" />
        </div>
        <div class="chat-body">
          <FloatingChatMessages
            ref="chatMessagesCom"
            :isLoading="isLoading"
            :messages="chatMessages"
          />
        </div>
        <div class="chat-form" @keyup.enter.exact="postMessage()">
          <b-field>
            <b-input
              v-model="chatMessage"
              :placeholder="$t('Type your message')+'...'"
              expanded
            ></b-input>
            <p class="control">
              <b-button
                :loading="isLoading"
                @click="postMessage()"
                icon-left="paper-plane"
                class="button is-info"
                >{{$t('Send')}}</b-button
              >
            </p>
          </b-field>
        </div>
        <!-- <div class="chat-footer">
          Chat footer goes here.
        </div> -->
      </div>

      <!-- Floating Chat Button -->
      <div v-if="!showChat" class="chat-button" @click="showChatBox()">
        <div class="robot-head">
          <!-- <img src="@/assets/robot-head.svg" alt="chat" /> -->
          <lottie-vue-player
            :src="$env.siteUrl+'animations/robot_head.json'"
            :autoplay="true"
            :player-controls="false"
            :loop="true"
            style="width: 80px; height: 80px; background-color: transparent"
          >
          </lottie-vue-player>
        </div>
        <div>{{$t('Help')}}?</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import FloatingChatMessages from "@/components/chat/FloatingChatMessages.vue";

export default {
  name: "FloatingChat",
  components: {
    FloatingChatMessages,
  },
  data() {
    return {
      isLoading: false,
      showChat: false,
      chatMessages: [],
      chatMessage: "",
      firstOpen: true,
    };
  },
  computed: {
    ...mapState(["lang", "existingSlugs"]),
  },
  methods: {
    getCurrentFormattedTime() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const day = String(now.getDate()).padStart(2, "0");
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const seconds = String(now.getSeconds()).padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    showChatBox() {
      this.showChat = true;
      //this.$refs.chatMessagesCom.scrollToBottom();
      //console.log(this.$refs);
      this.welcome();
      this.$nextTick(() => {
        this.$refs.chatMessagesCom.scrollToBottom();
      });
    },
    hideChatBox() {
      this.showChat = false;
      //this.chatMessages = [];
    },
    welcome() {
      let textMessage = "How can I help you?";
      if (this.lang == "en") {
        textMessage = "How can I help you? Are you looking for something?";
      } else if (this.lang == "ro") {
        textMessage = "Cum te pot ajuta? Cauti ceva?";
      } else if (this.lang == "ru") {
        textMessage = "Как я могу вам помочь? Вы что-то ищете?";
      }
      let message = {
        message: textMessage,
        created_at: this.getCurrentFormattedTime(),
        sender_id: false,
      };
      if (this.firstOpen) {
        this.chatMessages.push(message);
        this.firstOpen = false;
      }
    },
    postMessage() {
      if (!this.chatMessage) return;
      console.log("post message");
      let message = {
        message: this.chatMessage,
        created_at: this.getCurrentFormattedTime(),
        sender_id: true,
      };
      this.chatMessages.push(message);
      if (this.chatMessages.length > 20) {
        this.chatMessages.shift();
      }
      this.isLoading = true;
      this.getResponce(this.chatMessage);
      this.chatMessage = "";
    },
    parseTextToObject(text) {
      const startMarker = "[";
      const endMarker = "]";
      const startIndex = text.indexOf(startMarker);
      const endIndex = text.indexOf(endMarker, startIndex);

      if (startIndex === -1 || endIndex === -1) {
        // Return null or throw an error if the markers are not found
        return null;
      }

      // Extract the structured data
      const dataString = text
        .substring(startIndex + 1, endIndex)
        .split(",")
        .map((s) => s.trim());

      let trigger, category, subcategory;

      // Iterate through each piece of data and assign values accordingly
      dataString.forEach((data) => {
        const [key, value] = data.split(":").map((s) => s.trim());

        if (key === "trigger") {
          trigger = value;
        } else if (key === "category") {
          category = value;
        } else if (key === "subcategory") {
          subcategory = value;
        }
      });

      // Extract the message by removing the structured data
      const message = (
        text.substring(0, startIndex) + text.substring(endIndex + 1)
      ).trim();

      // Construct the object
      const result = {
        trigger: trigger || null,
        category: category || null,
        subcategory: subcategory || null,
        message,
      };

      return result;
    },
    checkIncomingMessage(message) {
      let parseResult = this.parseTextToObject(message.message);
      console.log("parseResult", parseResult);
      if (parseResult == null) {
        this.chatMessages.push(message);
        return;
      }
      message.message = parseResult.message;
      this.chatMessages.push(message);

      console.log("existingSlugs", this.existingSlugs);
      let openURL = "";
      //check if exixts in existingSlugs

      if (
        parseResult.category &&
        parseResult.subcategory &&
        this.existingSlugs.includes(parseResult.category) &&
        this.existingSlugs.includes(parseResult.subcategory)
      ) {
        openURL =
          "/category/" + parseResult.category + "/" + parseResult.subcategory;
      }
      if (
        parseResult.category &&
        !parseResult.subcategory &&
        this.existingSlugs.includes(parseResult.category)
      ) {
        openURL = "/category/" + parseResult.category;
      }
      if (openURL != "") {
        this.$router.push(openURL);
        this.hideChatBox();
      } else {
        let message = {
          message: "Sorry...",
          created_at: this.getCurrentFormattedTime(),
          sender_id: false,
        };
        this.chatMessages.push(message);
      }
    },
    getResponce(chatMessage) {
      //axios call to get responce from chatbot.php
      this.axios
        .post("chatgpt_new.php", {
          message: chatMessage,
          token: this.$store.state.tempToken,
          action: "chatBot",
        })
        .then((response) => {
          console.log(response.data);

            if (response.data) {
              let message = {
                message: response.data.message,
                created_at: this.getCurrentFormattedTime(),
                sender_id: false,
              };
              this.chatMessages.push(message);
              //this.checkIncomingMessage(message);
            } else {
              console.log("no choices");
            }
          
          // let message = {
          //   text: response.data,
          //   date: this.getCurrentFormattedTime(),
          //   isMine: false,
          // };
          // this.chatMessages.push(message);
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    postResponce() {
      console.log("post responce");
      let message = {
        message: "this is a responce!",
        created_at: this.getCurrentFormattedTime(),
        sender_id: false,
      };
      this.chatMessages.push(message);
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.chat-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 39; /*1000*/
  cursor: pointer;
  transition: all 0.3s;
}

.filter-on {
  right: 350px;
}

.chat-button {
  position: relative;
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  border-radius: 25px;
  transition: all 0.3s;
}

.chat-box {
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 506px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.chat-header,
.chat-footer {
  padding: 30px 10px 30px 20px;
  background-color: #f5f5f5;
  color: rgb(63, 63, 63);
  border: 1px solid #e6e6e6;
  font-weight: bold;
}

.chat-header {
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.4rem;
  position: relative;
}

.close-btn {
  cursor: pointer;
  padding-left: 10px;
}

.chat-footer {
  border-radius: 0 0 10px 10px;
}

.chat-body {
  flex: 1;
  overflow-y: auto;
  padding: 10px 2px 10px 10px;
}

.salex-line::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 6px;
  background: linear-gradient(
    to right,
    #ff008d 0%,
    #ff008d 25%,
    /* Color 1 */ #ffb000 25%,
    #ffb000 50%,
    /* Color 2 */ #00c793 50%,
    #00c793 75%,
    /* Color 3 */ #6e4fd7 75%,
    #6e4fd7 100% /* Color 4 */
  );
  z-index: 0; /* Send the gradient layer behind the original div content */
}

.robot-head {
  position: absolute;
  top: -70px;
  left: 0;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.robot-head img {
  width: 60px;
  height: 60px;
}

@media screen and (max-width: 1023px) {
  .chat-container {
    bottom: 75px;
    right: 10px;
  }
  /* .chat-box {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
  .chat-header {
    border-radius: 0;
  }
  .chat-footer {
    border-radius: 0;
  } */
}
</style>
