<template>
  <div class="card" :class="userID == advertData.u_id ? 'user-owned' : ''">
    <div>
      <div class="card-image">
        <figure
          class="hide-on-mobile image is-4by4"
          @mousemove="handleMouseMove"
          @mouseout="stopSlideshow"
        >
          <transition name="fade" mode="out-in">
            <router-link :to="'/advert/' + advertData.id">
              <img :src="showImage()" :alt="showTitle()" @error="imageError" />
            </router-link>
          </transition>
          <div class="dots">
            <span
              v-for="(image, index) in limitedImages"
              :key="index"
              :class="['dot', { active: currentIndex === index }]"
            ></span>
          </div>
        </figure>

        <figure class="hide-on-desktop image is-4by4">
          <Flicking :options="{ align: 'center', circular: true }" ref="flicking">
            <div
              v-for="(item, index) in limitedImages"
              class="flicking-panel"
              :key="'image-' + index"
            >
                <img
                  :alt="showTitle()"
                  class="image-item"
                  :src="showImageFlicking(item.image)"
                  @error="imageError"
                />
            </div>
          </Flicking>
        </figure>
      </div>
      <div class="card-content">
        <div class="adv-price-split">
          <div class="adv-price">
            <router-link :to="'/advert/' + advertData.id" class="card-link" v-if="advertData.ad_price != 0"> {{ advertData.ad_price }} {{ showCurrency(advertData.ad_currency) }}</router-link>
          </div>
          <div class="adv-heart"><FavHeart :advID="advertData.id" /></div>
        </div>
        <div class="content">
          <p class="title item-title is-5">
            <router-link :to="'/advert/' + advertData.id" class="card-link">{{
              showTitle()
            }}</router-link>
          </p>
          <p class="description">{{ showDescription() }}</p>
        </div>
      </div>
    </div>
    <div v-if="!isCategory">
      <div class="card-category">
        <div class="media-left">
          <router-link
            :to="categoryLink(advertData.cat_id, advertData.sub_id)"
            class="card-link"
          >
            <CategoriesIcon
              :category="{ icon: advertData.c_icon, color: advertData.c_color }"
            />
          </router-link>
        </div>
        <div class="media-content">
          <router-link
            :to="categoryLink(advertData.cat_id, advertData.sub_id)"
            class="card-link"
          >
            <p class="cat-title">{{ advertData["sc_label_" + lang] }}</p>
            <p class="sub-title">{{ advertData["c_label_" + lang] }}</p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CategoriesIcon from "@/components/adverts/CategoriesIcon.vue";
import { Flicking } from "@egjs/vue-flicking";

import FavHeart from "@/components/adverts/FavHeart.vue";
import translationHelper from "@/mixins/translationHelper";


export default {
  name: "AdvertCard",
  props: {
    advertData: {
      type: Object,
      required: true,
    },
  },
  components: {
    CategoriesIcon,
    FavHeart,
    Flicking,
  },
  mixins: [translationHelper],
  data() {
    return {
      currentYear: new Date().getFullYear(),
      category: null,
      subcategory: null,
      categoryData: null,
      currentIndex: 0,
      hoverTimer: null,
      isCategory: this.$route.name == "CategoryView" ? true : false,
      mouseX: 0,
      viewCount: 0,
      addedView: false,
    };
  },
  computed: {
    ...mapState({
      userID: (state) => state.users.user.id,
      lang: (state) => state.lang,
    }),
    ...mapState(["catrgoriesFlat"]),
    isOwner() {
      return (
        this.isLoged && this.advertData.user.id === this.$store.state.user.id
      );
    },
    limitedImages() {
      return this.advertData.ad_images.images.slice(0, 10);
    },
    // isExpired() {
    //   return this.advertData.expiration_date < this.currentYear;
    // },
    // isSold() {
    //   return this.advertData.sold;
    // }
  },
  mounted() {
    // this.$store.dispatch("getCategories");
    //console.log(this.categories);
    // if(this.categories){
    //   this.getCategoryById();
    // }
    //this.getCategoryById();
  },
  methods: {
    startSlideshow() {
      this.stopSlideshow(); // Clear any existing timer just in case
      this.hoverTimer = setInterval(() => {
        this.currentIndex++;
        if (this.currentIndex >= this.limitedImages.length) {
          this.currentIndex = 0; // Loop back to the first image
        }
      }, 1000); // Change image every 2000ms (2s)
    },
    stopSlideshow() {
      this.currentIndex = 0;
    },
    handleMouseMove(event) {
    let numImages = this.limitedImages.length;
    if (numImages <= 1) {
        return;
    }

    // Get the width of the target element
    let divWidth = event.target.offsetWidth;

    // Calculate the width of each 'virtual' section
    let sectionWidth = divWidth / numImages;

    // Calculate which section the mouse is currently over
    let mouseXRelativeToDiv = event.pageX - event.target.getBoundingClientRect().left;
    let currentSection = Math.floor(mouseXRelativeToDiv / sectionWidth);

    // Set the image index to the section number
    //this.currentIndex = currentSection;

    //count changes in currentIndex
    if(this.viewCount < 3 && this.currentIndex !== currentSection){
      //console.log("viewCount: "+this.viewCount);
      this.viewCount++;
    }else if(this.viewCount == 3 && this.currentIndex == currentSection){
      if(!this.addedView){
        this.addView();
        this.addedView = true;
      }
      //console.log("adding view: "+this.viewCount);
      this.viewCount = 0;
    }

    this.currentIndex = currentSection;

    // Update the displayed image based on this.currentIndex
    // You might have some method like this.updateDisplayedImage() or similar
    // this.updateDisplayedImage();
    },
    imageError(event) {
      event.target.src = "https://salex.md/img/noimage.png";
    },
    goToAdv() {
      // this.$router.push({
      //   name: "AdvertView",
      //   params: {
      //     id: this.advertData.id
      //   }
      // });
      this.$router.push({ path: "/advert/" + this.advertData.id });
    },
    goToCategory(cat_id, sub_id) {
      // console.log(this.catrgoriesFlat);
      //find category by id
      let foundCat = this.catrgoriesFlat.find(
        (category) => category.id == cat_id
      );
      //find subcategory by id
      let foundSub = foundCat.subcategories.find(
        (subcategory) => subcategory.id == sub_id
      );
      //avoid if already on category page
      if (
        this.$route.path ==
        "/category/" + foundCat.slug + "/" + foundSub.slug
      ) {
        return;
      }
      this.$router.push({
        path: "/category/" + foundCat.slug + "/" + foundSub.slug,
      });
    },
    categoryLink(cat_id, sub_id) {
      // console.log(this.catrgoriesFlat);
      //find category by id
      let foundCat = this.catrgoriesFlat.find(
        (category) => category.id == cat_id
      );
      //find subcategory by id
      if (!foundCat) {
        return "";
      }
      let foundSub = foundCat.subcategories.find(
        (subcategory) => subcategory.id == sub_id
      );
      //avoid if already on category page
      return "/category/" + foundCat.slug + "/" + foundSub.slug;
    },
    //sorten description
    // showDescription() {
    //   if (this.advertData[this.descrLabels[this.$store.state.lang]] == "") {
    //     return this.shortenDescription(
    //       this.advertData[this.descrLabels[this.advertData.ad_lang]]
    //     );
    //   } else {
    //     return this.shortenDescription(
    //       this.advertData[this.descrLabels[this.$store.state.lang]]
    //     );
    //   }
    // },
    // showTitle() {
    //   //fallback to main language if no content
    //   if (this.advertData[this.titlesLabels[this.$store.state.lang]] == "") {
    //     return this.advertData[this.titlesLabels[this.advertData.ad_lang]];
    //   } else {
    //     return this.advertData[this.titlesLabels[this.$store.state.lang]];
    //   }
    // },
    showTitle(itemObj=this.advertData){
      let currentLang = this.$store.state.lang;
      let titleLabels = this.titlesLabels;
    // First, try the current language
    if (itemObj[titleLabels[currentLang]] && itemObj[titleLabels[currentLang]] !== "") {
      return itemObj[titleLabels[currentLang]];
    }

    // Next, try the item's default language
    if (itemObj.ad_lang && itemObj[titleLabels[itemObj.ad_lang]] && itemObj[titleLabels[itemObj.ad_lang]] !== "") {
      return itemObj[titleLabels[itemObj.ad_lang]];
    }

    // Finally, check for any non-empty title in the known languages
    for (const langCode of Object.keys(titleLabels)) {
      if (itemObj[titleLabels[langCode]] && itemObj[titleLabels[langCode]] !== "") {
        return itemObj[titleLabels[langCode]];
      }
    }
    // If no title is available, return an empty string or a placeholder
    return "Title not available.";
  },

  showDescription(itemObj=this.advertData){
    let currentLang = this.$store.state.lang;
    let descrLabels = this.descrLabels;
    // First, try the current language
    if (itemObj[descrLabels[currentLang]] && itemObj[descrLabels[currentLang]] !== "") {
      return this.shortenDescription(itemObj[descrLabels[currentLang]]);
    }
  
    // Next, try the item's default language
    if (itemObj.ad_lang && itemObj[descrLabels[itemObj.ad_lang]] && itemObj[descrLabels[itemObj.ad_lang]] !== "") {
      return this.shortenDescription(itemObj[descrLabels[itemObj.ad_lang]]);
    }
  
    // Finally, check for any non-empty description in the known languages
    for (const langCode of Object.keys(descrLabels)) {
      if (itemObj[descrLabels[langCode]] && itemObj[descrLabels[langCode]] !== "") {
        return this.shortenDescription(itemObj[descrLabels[langCode]]);
      }
    }
  
    // If no description is available, return an empty string or a placeholder
    return "Description not available.";
  },
     //sorten description
    shortenDescription(description) {
      if (description.length > 300) {
        return description.substring(0, 300) + "...";
      } else {
        return description;
      }
    },
    //show image or blank
    // showImage(){
    //   if(this.advertData.ad_images.images.length == 0){
    //     return 'https://salex.md/img/noimage.png';
    //   }else{
    //     return this.$env.imagesURL + this.advertData.ad_images.images[0].image;
    //   }
    // },
    showImage() {
      if (this.limitedImages.length === 0) {
        return "https://salex.md/img/noimage.png";
      } else {
        let imageObj = this.limitedImages[this.currentIndex];
        if (imageObj) {
          let image = imageObj.image.replace(/\.[^/.]+$/, "") + "_h400.webp";
          return this.$env.imagesURL + "gallery/400/" + image;
        } else {
          return "https://salex.md/img/noimage.png";
        }
      }
    },
    showImageFlicking(image) {
      if (this.limitedImages.length === 0) {
        return "https://salex.md/img/noimage.png";
      } else {
        if (image) {
          let processedImage = image.replace(/\.[^/.]+$/, "") + "_h400.webp";
          return this.$env.imagesURL + "gallery/400/" + processedImage;
        } else {
          return "https://salex.md/img/noimage.png";
        }
      }
    },

    showCurrency(currency) {
      if(!currency) return;
      let currencies = {
        MDL: "Lei",
        EUR: "€",
        USD: "$",
      };
      return currencies[currency];
    },
    addView() {
      console.log("adding view");
      this.axios
        .post("/adverts/manage_views.php", {
          action: "addView",
          itemID: this.advertData.id,
          token: this.$store.state.tempToken,
        }).then((response) => {
          console.log(response.data);
          this.adViews = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //find category by id
    // getCategoryById(){
    //   let foundCat = this.categories.find(category => category.id == this.advertData.cat_id);
    //   this.category = foundCat.labelEN;
    //   let foundSub = foundCat.subcategories.find(subcategory => subcategory.id == this.advertData.sub_id);
    //   this.subcategory = foundSub.labelEN;
    // },
    // getCategoryDataById(){
    //   return this.categories.find(category => category.id == this.advertData.cat_id);
    // }
  },
  watch: {
    // categories: function(){
    //   this.getCategoryById();
    // }
  },
};
</script>

<style scoped>
.card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-content {
  background-color: transparent;
  padding: 1rem;
}
.card-image {
  padding: 1rem 1rem 0 1rem;
}

.card-image img {
  object-fit: cover;
  width: 100%;
  height: 300px;
}

.card-category {
  display: flex;
  align-items: center;
  padding: 1rem;
}

.description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.item-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.cat-title {
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.2;
}

.sub-title {
  font-size: 0.9rem;
}

.user-owned {
  background-color: #f5f5f5;
  border: 2px solid #67a86f;
}

.card-link {
  color: #000;
}
.card-link:hover {
  color: #595d61;
}
.adv-price-split {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}
.adv-price {
  font-size: 1.5rem;
  font-weight: 600;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.dots {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  margin-bottom: 10px;
  pointer-events: none;  
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 0 5px;
  background-color: rgba(255, 255, 255, 0.6);
  pointer-events: none;  
}

.dot.active {
  background-color: #fff;
  width: 16px;
  height: 16px;
}

.flicking-panel {
  position: relative;
  width:100%;
  cursor: pointer;
}
</style>
