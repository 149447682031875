<template>
  <div class="scroll-item" ref="scrollContainer">
    <div class="sx-chat">
      <template v-for="(message, index) in messages">
        <div class="message-wrapper"
          @mouseover="showDate = index"
          @mouseleave="showDate = -1"
          :class="
            isMine(message.sender_id) ? 'sx-sent' : 'sx-received'
          "
        >
          <div v-if="showDate==index" class="message-date">{{formatDateTime(message.created_at, lang)}}</div>
          <div class="message-content">
            <div class="message-text" v-html="parseMessage(message.message)"></div>
          </div>
        </div>
      </template>
      <div class="is-typing" v-if="isLoading">
        <div class="is-typing__dots">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin.js";

export default {
  name: "FloatingChatMessages",
  props: {
    messages: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  mixins: [formatMixin],
  data() {
    return {
      showDate: -1,
    };
  },
  computed: {
    ...mapState(["lang"]),
    ...mapState({
      user: (state) => state.users.user,
    }),
  },
  methods: {
    showImage(image) {
      if (image) {
        let imageOut = image.replace(/\.[^/.]+$/, "") + "_h400.webp";
        return this.$env.imagesURL + "gallery/400/" + imageOut;
      } else {
        return "https://salex.md/img/noimage.png";
      }
    },
    isMine(id) {
      if(id==false) return false;
      if(id==true) return true;
      return this.user.id == id;
    },
    scrollToBottom() {
      this.$nextTick(() => {
        if(this.showDate==-1){
          this.$refs.scrollContainer.scrollTop = this.$refs.scrollContainer.scrollHeight;
        }
      });
    },
    parseMessage(message) {
      // Escape HTML to prevent XSS
      let escapedMessage = this.escapeHtml(message);

      // Parse product listings first
      escapedMessage = this.parseProductListings(escapedMessage);

      // Parse markdown-like bold
      escapedMessage = escapedMessage.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

      // Parse image links
      escapedMessage = escapedMessage.replace(/!\[([^\]]+)\]\(([^\)]+)\)/g, 
        (match, alt, src) => `<img src="${this.showImage(src)}" alt="${alt}" class="inline-image" />`);

      // Parse hyperlinks
      escapedMessage = escapedMessage.replace(/\[([^\]]+)\]\(([^\)]+)\)/g, 
        '<a href="https://$2" target="_blank" class="message-link">$1</a>');

      // Preserve line breaks
      escapedMessage = escapedMessage.replace(/\n/g, '<br>');

      return escapedMessage;
    },
    parseProductListings(message) {
      // Regex to detect product listing pattern
      const listingRegex = /^I found (\d+) iPhone listings for you:\n\n((?:.*\n)*)/;
      const match = message.match(listingRegex);

      if (match) {
        const count = match[1];
        const listingsText = match[2];

        // Parse individual listings
        const listingsHtml = listingsText.split(/\n\n/).map(listing => {
          if (listing.trim()) {
            const lines = listing.split('\n');
            const titleMatch = lines[0].match(/\*\*(.*)\*\*/);
            const title = titleMatch ? titleMatch[1] : 'Untitled';
            
            const priceMatch = lines.find(line => line.includes('Price:'));
            const price = priceMatch ? priceMatch.replace('- Price: ', '') : 'N/A';

            // Only take the first image
            const firstImageLine = lines.find(line => line.startsWith('- !['));
            const firstImage = firstImageLine 
              ? (() => {
                  const imgMatch = firstImageLine.match(/!\[.*\]\((.*)\)/);
                  return imgMatch 
                    ? `<img src="${this.showImage(imgMatch[1])}" alt="Product Image" class="product-image">` 
                    : '';
                })()
              : '';

            const linkMatch = lines.find(line => line.startsWith('- [Link'));
            const link = linkMatch 
              ? `<a href="https://${linkMatch.match(/\((.*)\)/)[1]}" target="_blank" class="product-link">View Details</a>` 
              : '';

            return `
              <div class="product-listing">
                <h3>${title}</h3>
                <p>Price: ${price}</p>
                <div class="product-images">${firstImage}</div>
                ${link}
              </div>
            `;
          }
          return '';
        }).join('');

        return `<div class="product-listings">
          <p>I found ${count} iPhone listings for you:</p>
          ${listingsHtml}
        </div>`;
      }

      return message;
    },
    escapeHtml(unsafe) {
      return unsafe
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
    }
  },
  watch: {
    messages: {
      handler: function (val, oldVal) {
        this.scrollToBottom();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.scroll-item {
  overflow: auto;
  height: 350px;
}

.sx-chat {
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.message-wrapper {
  display: inline-block;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  max-width: 90%;
  min-width: 120px;
  position: relative;
  word-wrap: break-word;
}

.message-content {
  display: inline-block;
  max-width: 100%;
}

.sx-sent {
  background-color: #f5f5f5;
  align-self: flex-end;
}

.sx-received {
  background-color: #cdffea;
  align-self: flex-start;
}

.message-text {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  color: hsl(0, 0%, 43%);
}

.message-date {
  position: absolute;
  bottom: -0.6rem;
  font-size: 0.6rem;
  line-height: 1;
  font-weight: 400;
  color: hsl(0, 0%, 52%);
  background-color: hsl(0deg, 0%, 100%);
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
}

.sx-sent .message-date {
  border: 1px solid #e6e6e6;
  right: 0;
}

.sx-received .message-date {
  border: 1px solid #cdffea;
  left: 0;
}

/* Product Listings Styles */
.product-listings {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
}

.product-listing {
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 0;
}

.product-listing:last-child {
  border-bottom: none;
}

.product-listing h3 {
  margin: 0 0 10px 0;
  color: #333;
}

.product-images {
  display: flex;
  margin: 10px 0;
}

.product-image {
  max-width: 200px;
  max-height: 200px;
  object-fit: cover;
  border-radius: 4px;
}

.product-link {
  display: inline-block;
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  text-decoration: none;
  border-radius: 4px;
  margin-top: 10px;
}

.inline-image {
  max-width: 200px;
  max-height: 200px;
  object-fit: cover;
  border-radius: 4px;
  margin: 5px 0;
}

.message-link {
  color: #007bff;
  text-decoration: none;
}

.message-link:hover {
  text-decoration: underline;
}

/* Scrollbar Styles */
.scroll-item::-webkit-scrollbar {
  width: 4px;
}

.scroll-item::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.scroll-item::-webkit-scrollbar-thumb {
  background: #979797;
}

/* Is Typing Styles */
.is-typing {
  display: inline-block;
  margin-left: 10px;
  font-size: 14px;
  color: #777;
}

.is-typing__dots {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 20px;
}

.is-typing__dots span {
  display: inline-block;
  width: 5px;
  height: 5px;
  margin-right: 3px;
  border-radius: 50%;
  background-color: #777;
  animation: is-typing 1s ease-in-out infinite;
}

@keyframes is-typing {
  0% {
    opacity: 0.2;
    transform: translateY(0);
  }
  25% {
    opacity: 1;
    transform: translateY(-5px);
  }
  50% {
    opacity: 0.2;
    transform: translateY(0);
  }
}
</style>
