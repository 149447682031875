export default {
  methods: {
    toast(message, type, position = "is-top", duration = 5000) {
      this.$buefy.toast.open({
        duration: duration,
        position: position,
        type: type,
        message: this.$i18n.t(message),
      });
    },
  },
};
