<template>
  <section>
    <b-modal
      :active.sync="isActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
      :can-cancel="false"
    >
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">{{$t("Restore Acces")}}</p>
          <button type="button" class="delete" @click="closeModal()" />
        </header>
        <section class="modal-card-body">
          <b-loading
            :is-full-page="false"
            :active.sync="isLoading"
            :can-cancel="false"
          ></b-loading>

          <div v-if="step == 1">
            <form autocomplete="off">
              <b-field>
                <b-radio-button
                  v-model="restoreType"
                  native-value="phone"
                  type="is-success"
                  expanded
                >
                  <span>{{$t("Phone")}}</span>
                </b-radio-button>

                <b-radio-button
                  v-model="restoreType"
                  native-value="email"
                  type="is-success"
                  expanded
                >
                  <span>{{$t("Email")}}</span>
                </b-radio-button>
              </b-field>

              <b-field
                v-if="restoreType == 'phone'"
                :label="$t('Phone')"
                :type="form.phone.status"
                :message="form.phone.message"
              >
                <p class="control">
                  <span class="button is-static">+373</span>
                </p>
                <b-input
                  type="text"
                  v-model="form.phone.data"
                  :placeholde="$t('Your Phone')"
                  expanded
                ></b-input>
              </b-field>

              <b-field
                v-if="restoreType == 'email'"
                :label="$t('Email')"
                :type="form.email.status"
                :message="form.email.message"
              >
                <b-input
                  type="email"
                  v-model="form.email.data"
                  :placeholder="$t('Your Email')"
                  expanded
                ></b-input>
              </b-field>
            </form>
          </div>

          <div v-if="step == 2">
            <div class="mb-2"><strong>{{$t("Verification code")}}:</strong></div>
            <form autocomplete="off" v-on:submit.prevent="checkCode()">
              <b-field grouped>
                <b-field class="code-input">
                  <b-input
                    ref="input1"
                    size="is-large"
                    @input="fucusNext('input2')"
                    v-model="codeInput.code1"
                    autocomplete="off"
                    :disabled="isCheckingCode"
                  ></b-input>
                  <b-input
                    ref="input2"
                    size="is-large"
                    @input="fucusNext('input3')"
                    v-model="codeInput.code2"
                    maxlength="1"
                    :has-counter="false"
                    autocomplete="off"
                    :disabled="isCheckingCode"
                  ></b-input>
                  <b-input
                    ref="input3"
                    size="is-large"
                    @input="fucusNext('input4')"
                    v-model="codeInput.code3"
                    maxlength="1"
                    :has-counter="false"
                    autocomplete="off"
                    :disabled="isCheckingCode"
                  ></b-input>
                  <b-input
                    ref="input4"
                    size="is-large"
                    @input="fucusNext('input5')"
                    v-model="codeInput.code4"
                    maxlength="1"
                    :has-counter="false"
                    autocomplete="off"
                    :disabled="isCheckingCode"
                  ></b-input>
                  <b-input
                    ref="input5"
                    size="is-large"
                    @input="checkCode()"
                    v-model="codeInput.code5"
                    maxlength="1"
                    :has-counter="false"
                    autocomplete="off"
                    :disabled="isCheckingCode"
                  ></b-input>
                </b-field>
              </b-field>
            </form>
          </div>

          <div v-if="step == 3">
            <form autocomplete="off">
              <b-field
                :label="$t('Create New Password')"
                :type="form.pass.status"
                :message="form.pass.message"
              >
                <b-input
                  autocomplete="off"
                  type="password"
                  v-model="form.pass.data"
                  :placeholder="$t('Your New Password')"
                  expanded
                ></b-input>
              </b-field>
            </form>
          </div>

          <b-message class="my-2" :type="form.status" v-if="form.result">
            {{ $t(form.message) }}
          </b-message>

          <div v-if="step == 4" class="has-text-centered">
            <b-button type="is-success" @click="openLogin()">
              {{ $t("Login Now") }}
            </b-button>
          </div>

          <div v-if="step == 2 && wrongCode" class="has-text-centered">
            <b-button type="is-light" @click="tryNewPhone()">
              {{ $t("Try Another Phone Number") }}
            </b-button>
          </div>
        </section>
        <footer class="modal-card-foot" v-if="step != 4">
          <b-button type="is-light" @click="closeModal()">{{ $t("Cancel") }}</b-button>

          <b-button
            v-if="step == 1"
            type="is-info"
            @click="restoreAccess()"
            :loading="isLoading"
          >
            {{ $t("Restore Access") }}
          </b-button>

          <b-button
            v-if="step == 2"
            type="is-info"
            @click="resendCode()"
            :disabled="countBack > 0"
          >
          {{ $t("Resend Code") }}
            <span v-if="countBack > 0">&nbsp;({{ countBack }})</span>
          </b-button>

          <b-button
            v-if="step == 3"
            type="is-info"
            @click="savePass()"
            :loading="isLoading"
          >
          {{ $t("Save Password") }}
          </b-button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import formCheck from "@/mixins/formCheck";
export default {
  name: "RestoreModal",
  mixins: [formCheck],
  data() {
    return {
      step: 1,
      isLoading: false,
      isCheckingCode: false,
      countErrors: 0,
      restoreType: "phone",
      wrongCode: false,
      codeIsValid: false,
      countBack: 30,
      codeInput: {
        code1: "",
        code2: "",
        code3: "",
        code4: "",
        code5: "",
      },
      form: {
        email: {
          data: "",
          status: "",
          message: "",
        },
        phone: {
          data: "",
          status: "",
          message: "",
        },
        pass: {
          data: "",
          status: "",
          message: "",
        },
        visible: true,
        message: "",
        result: false,
        status: "",
      },
      errorSending: false, 
      verificationURL: "libs/verify_phone_context.php",
    };
  },
  methods: {
    checkPhone() {
      return new Promise((resolve, reject) => {
        if (this.formCheck("phone")) {
          this.axios
            .post("libs/check_avalible.php", {
              phone: this.form.phone.data,
              action: "checkRegisteredPhone",
              token: this.$store.state.tempToken,
            })
            .then((response) => {
              console.log("checkPhone:", response.data);
              if (response.data.success) {
                resolve(true);
              } else {
                this.form.phone.message = response.data.message;
                this.form.phone.status = "is-danger";
                resolve(false);
              }
            })
            .catch((error) => {
              console.log(error);
              reject(false);
            });
        } else {
          this.form.status = "is-danger";
          this.form.result = true;
          this.form.message = "Ooops! Something went wrong. Please try again.";
          resolve(false);
        }
      });
    },

    setCounter() {
      this.countBack = 30;
      this.countBack--;
      let interval = setInterval(() => {
        this.countBack--;
        if (this.countBack == 0) {
          clearInterval(interval);
        }
      }, 1000);
    },

    tryNewPhone() {
      this.step = 1;
      //========================//
      this.wrongCode = false;
      this.isCheckingCode = false;
      this.phone.message = "";
      this.resetCode();
      this.clearResponce();
    },

    fucusNext(nextInputRef) {
      console.log(nextInputRef);
      if (nextInputRef == "input2") {
        console.log(this.codeInput.code1);
        if (this.codeInput.code1.length >= 5) {
          console.log("full code");
          let fullCode = this.codeInput.code1.trim();
          this.codeInput.code1 = fullCode.substring(0, 1);
          this.codeInput.code2 = fullCode.substring(1, 2);
          this.codeInput.code3 = fullCode.substring(2, 3);
          this.codeInput.code4 = fullCode.substring(3, 4);
          this.codeInput.code5 = fullCode.substring(4, 5); // 12376
          this.checkCode();
          return;
        }
      }
      this.$nextTick(() => {
        this.$refs[nextInputRef].focus();
      });
    },

    clearCode() {
      this.codeInput.code1 = "";
      this.codeInput.code2 = "";
      this.codeInput.code3 = "";
      this.codeInput.code4 = "";
      this.codeInput.code5 = "";
    },

    resendCode() {
      if(this.verificationURL == "libs/verify_phone.php"){
        this.verificationURL = "libs/verify_phone_context.php";
      }else{
        this.verificationURL = "libs/verify_phone.php";
      }
      this.sendCode();
    },

    restoreAccess() {
      this.clearResponce();
      if (this.restoreType == "phone") {
        this.checkPhone().then((checkResult) => {
          if (checkResult) {
           this.sendCode();
           //console.log("sendCode");
          } else {
            this.form.phone.status = "is-danger";
            this.form.message =
              "This Phone did not pass verifivation. Please try again.";
            this.form.result = true;
            this.form.status = "is-danger";
          }
        });
      } else {
        this.restoreByEmail();
      }
    },

    sendCode() {
      this.setCounter();
      console.log("sendCode");
      console.log(this.$store.state.tempToken);
      //begin post -----------------------------------------------
      this.axios
        .post(this.verificationURL, {
          phone: "+373"+this.form.phone.data.trim(),
          action: "sendCode",
          token: this.$store.state.tempToken,
        })
        .then((response) => {
          this.isLoading = false;
          this.form.phone.message = response.data.message;
          console.log(response.data);
          if (response.data.success) {
            this.step = 2;
            this.$nextTick(() => {
              this.$refs["input1"].focus();
            });
          } else {
            this.form.phone.status = "is-danger";
            this.form.message =
              "Ooops! Could not send verification code. Please try again.";
            this.form.result = true;
            this.form.status = "is-danger";
            this.errorSending = true;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.errorSending = true;
          this.form.phone.status = "is-danger";
          this.form.message = "Ooops! Could not send verification code. Please try again.";
          this.form.result = true;
          this.form.status = "is-danger";
          console.log(error);
          //this.hasError();
        });
      //end post-------------------------------------------------
    },

    checkCode() {
      this.clearResponce();
      this.isCheckingCode = true;
      this.isLoading = true;
      let code =
        "" +
        this.codeInput.code1 +
        this.codeInput.code2 +
        this.codeInput.code3 +
        this.codeInput.code4 +
        this.codeInput.code5;
      //begin post --------------------------------------------
      this.axios
        .post(this.verificationURL, {
          phone: "+373"+this.form.phone.data.trim(),
          code: code,
          action: "checkCode",
          token: localStorage.sxToken,
        })
        .then((response) => {
          this.isLoading = false;
          console.log(response.data);
          if (response.data.success) {
            this.isCheckingCode = false;
            this.codeIsValid = true;
            this.wrongCode = false;
            this.step = 3;
          } else {
            this.form.message = response.data.message;
            this.form.status = "is-danger";
            this.form.result = true;
            this.wrongCode = true;
            this.resetCode();
            if(!this.form.message){
              this.form.message = "Ooops! Something went wrong. Please try again.";
            }
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.isCheckingCode = false;
          this.form.message = "Unexpected error. Please try again.";
          this.form.status = "is-danger";
          this.form.result = true;
          console.log(error);
          //this.hasError();
        });
      // //end post-------------------------------------------------
    },

    restoreByEmail() {
      if (this.emailCheck()) {
        this.isLoading = true;
        //begin post --------------------------------------------
        this.axios
          .post("users/restore.php", {
            action: "restore",
            email: this.form.email.data,
            restoreType: this.restoreType,
            token: this.$store.state.tempToken,
          })
          .then((response) => {
            this.form.result = true;
            this.form.message = response.data.message;
            console.log(response.data);
            if (response.data.success) {
              this.step = 4;
              this.form.status = "is-success";
            } else {
              this.form.status = "is-danger";
            }
          })
          .catch((error) => {
            console.log(error);
            //this.hasError();
          })
          .finally(() => {
            this.isLoading = false;
          });
        // //end post-------------------------------------------------
      }
    },

    savePass() {
      if (this.codeIsValid & this.passCheck()) {
        this.isLoading = true;
        //begin post --------------------------------------------
        this.axios
          .post("users/restore.php", {
            action: "restore",
            phone: this.form.phone.data,
            password: this.form.pass.data,
            restoreType: this.restoreType,
            token: this.$store.state.tempToken,
          })
          .then((response) => {
            this.form.result = true;
            this.form.message = response.data.message;
            console.log(response.data);
            if (response.data.success) {
              this.step = 4;
              this.form.status = "is-success";
            } else {
              this.form.status = "is-danger";
            }
          })
          .catch((error) => {
            console.log(error);
            //this.hasError();
          })
          .finally(() => {
            this.isLoading = false;
          });
        //end post-------------------------------------------------
      } else {
        this.form.status = "is-danger";
        this.form.result = true;
        this.form.message = "Ooops! Something went wrong. Please try again.";
      }
    },

    openLogin() {
      this.$store.commit("modals/showModal", "login");
      this.closeModal();
    },

    resetCode() {
      this.codeInput.code1 = "";
      this.codeInput.code2 = "";
      this.codeInput.code3 = "";
      this.codeInput.code4 = "";
      this.codeInput.code5 = "";
      if (this.isCheckingCode) {
        this.isCheckingCode = false;
        this.$refs["input1"].focus();
      }
    },

    resetForm() {
      this.step = 1;
      this.form.email.data = "";
      this.form.email.status = "";
      this.form.email.message = "";

      this.form.phone.data = "";
      this.form.phone.status = "";
      this.form.phone.message = "";

      this.form.message = "";
      this.form.result = false;
      this.form.status = "";
    },

    clearResponce() {
      this.form.message = "";
      this.form.result = false;
      this.form.status = "";
    },

    closeModal() {
      this.resetForm();
      this.resetCode();
      this.$store.commit("modals/hideModal", "restore");
    },
  },
  computed: {
    isActive: {
      get: function () {
        return this.$store.state.modals.restore;
      },
      // setter
      set: function () {
        this.$store.commit("modals/hideModal", "restore");
        return false;
      },
    },
  },
  watch: {
    isActive: function () {
      this.resetForm();
    },
  },
};
</script>
<style scoped>
.modal-card-foot {
  justify-content: space-between;
  /* flex-end; */
}
</style>
